import { Navbar } from './components/navbar'
import '../scss/base.scss'

class CAPP {
  constructor() {
    document.addEventListener('DOMContentLoaded', () => {
      this.navbar = new Navbar()
    })
  }
}

export const APP = new CAPP()
